import React from 'react';
import Components from '../components/Components.js';
import SbEditable from 'storyblok-react';
import config from '../../gatsby-config';

const loadStoryblokBridge = function(cb) {
    let sbConfigs = config.plugins.filter(item => {
        return item.resolve === `gatsby-source-storyblok`;
    });
    let sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {};
    let script = document.createElement(`script`);
    script.type = `text/javascript`;
    script.src = `//app.storyblok.com/f/storyblok-latest.js?t=uJ2KMbzkBzVM1v6suM05QQtt`;
    script.onload = cb;
    document.getElementsByTagName(`head`)[0].appendChild(script);
};

const getParam = function(val) {
    var result = ``;
    var tmp = [];

    window.location.search
        .substr(1)
        .split(`&`)
        .forEach(function(item) {
            tmp = item.split(`=`);
            if (tmp[0] === val) {
                result = decodeURIComponent(tmp[1]);
            }
        });

    return result;
};

class StoryblokEntry extends React.Component {
    constructor(props) {
        super(props);
        this.state = { story: null, nav: null };
    }

    componentDidMount() {
        loadStoryblokBridge(() => {
            this.initStoryblokEvents();
        });
    }

    loadStory() {
        window.storyblok.get(
            {
                slug: getParam(`path`),
                version: `draft`,
            },
            data => {
                this.setState({ story: data.story });
            }
        );
    }

    loadGlobalNavi() {
        window.storyblok.get(
            {
                slug: `global/main-navigation`,
                version: `draft`,
            },
            data => {
                this.setState({ nav: data.story });
            }
        );
    }

    initStoryblokEvents() {
        this.loadStory({ storyId: getParam(`path`) });
        this.loadGlobalNavi();

        let sb = window.storyblok;

        sb.on([`change`, `published`], payload => {
            this.loadStory(payload);
        });

        sb.on(`input`, payload => {
            if (this.state.story && payload.story.id === this.state.story.id) {
                payload.story.content = sb.addComments(
                    payload.story.content,
                    payload.story.id
                );
                this.setState({ story: payload.story });
            }
        });

        sb.pingEditor(() => {
            if (sb.inEditor) {
                sb.enterEditmode();
            }
        });
    }

    render() {
        if (this.state.story == null || this.state.nav == null) {
            return <div></div>;
        }
        const content = this.state.story.content;
        const nav = {
            items: this.state.nav.content.main_navigation,
        };
        const lang = this.state.nav.lang;
        const metadata = {
            published: this.state.story.published_at,
        };

        return (
            <SbEditable content={content}>
                <div>
                    {React.createElement(Components(content.component), {
                        key: content._uid,
                        blok: content,
                        nav,
                        metadata,
                        lang,
                    })}
                </div>
            </SbEditable>
        );
    }
}

export default StoryblokEntry;
