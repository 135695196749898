require(`dotenv`).config({
    path: `.env`,
});

const IS_PROD = process.env.CONTEXT && process.env.CONTEXT === `production`;

const {
    NODE_ENV,
    URL: NETLIFY_SITE_URL = `https://www.saltoks.com`,
    DEPLOY_PRIME_URL: NETLIFY_DEPLOY_URL = NETLIFY_SITE_URL,
    CONTEXT: NETLIFY_ENV = NODE_ENV,
} = process.env;
const isNetlifyProduction = NETLIFY_ENV === `production`;
const siteUrl = isNetlifyProduction ? NETLIFY_SITE_URL : NETLIFY_DEPLOY_URL;

module.exports = {
    siteMetadata: {
        title: `SALTO KS | Cloud-Based Smart Locks, Wireless Access Control`,
        description: `SALTO KS, SALTO Keys as a Service - Wireless Access Control For Your Business. Cloud-Based, Real-Time And On-The-Go Smart Lock Solution. Go Keyless Now!`,
        author: `@jdujaka`,
        siteUrl: siteUrl,
        HOMERUN_API_KEY: process.env.HOMERUN_API_KEY,
    },
    plugins: [
        `gatsby-plugin-react-helmet`,
        `gatsby-plugin-instagram`,
        `gatsby-plugin-sass`,
        {
            resolve: `gatsby-source-filesystem`,
            options: {
                name: `images`,
                path: `${__dirname}/src/images`,
            },
        },
        `gatsby-transformer-sharp`,
        `gatsby-plugin-sharp`,
        {
            resolve: `gatsby-source-storyblok`,
            options: {
                accessToken: process.env.STORYBLOK_API_KEY,
                homeSlug: `home`,
                version: IS_PROD ? `published` : `draft`,
                includeLinks: true,
                languagese: [`en`],
            },
        },
        {
            resolve: `@sentry/gatsby`,
            options: {
                dsn: `https://f7cc4bca94a0459593e58a0b4209a839@o205718.ingest.sentry.io/5420996`,
                sampleRate: 0.4,
            },
        },
        {
            resolve: `gatsby-plugin-google-tagmanager`,
            options: {
                id: process.env.GOOGLE_TAGMANAGER_ID,
            },
        },
        // this (optional) plugin enables Progressive Web App + Offline functionality
        // To learn more, visit: https://gatsby.dev/offline
        {
            resolve: `gatsby-plugin-manifest`,
            options: {
                name: `SALTO KS | Cloud-Based Smart Locks, Wireless Access Control`,
                short_name: `SALTO KS`,
                start_url: `/`,
                background_color: `#FFF`,
                theme_color: `#0088ce`,
                display: `standalone`,
                icon: `static/salto-icon.png`,
            },
        },
        {
            resolve: `gatsby-plugin-google-fonts`,
            options: {
                fonts: [`roboto\:400,400i,700`],
                fontDisplay: `swap`,
            },
        },
    ],
};
